
























































































































































































































import {format} from 'date-fns'
import {Component, Vue, Watch} from 'vue-property-decorator'

import ConfigErrorId from '@/enums/configErrorId'
import ellipsisUnnecessaryParams from '@/helpers/ellipsisUnnecessaryParams'
import formatConfigExtract from '@/helpers/formatConfigExtract'
import getTileDocUrl from '@/helpers/getTileDoc'
import guessExpectedFieldName from '@/helpers/guessExpectedFieldName'
import guessExpectedValue from '@/helpers/guessExpectedValue'
import hasConfigVerifyErrors from '@/helpers/hasConfigVerifyErrors'
import parsedExtractFieldValue from '@/helpers/parsedExpectedValue'
import splitList from '@/helpers/splitList'
import ConfigError from '@/interfaces/configError'
import {DEFAULT_CONFIG_NAME} from '@/store'

@Component({})
export default class MonitororErrors extends Vue {
  /**
   * Computed
   */

  get classes() {
    return {
      'c-monitoror-errors__config-verify-errors': this.hasConfigVerifyErrors,
    }
  }

  get configParam(): string {
    return this.$store.getters.configParam
  }

  get isUsingDefaultConfig(): boolean {
    return this.configParam === DEFAULT_CONFIG_NAME
  }

  get isCoreDown(): boolean {
    if (!this.hasErrors) {
      return false
    }

    return this.errors[0].id === ConfigErrorId.CannotBeFetched
  }

  get lastRefreshDate(): string {
    return format(this.$store.state.lastRefreshDate, 'hh:mm:ss a')
  }

  get isOnline(): boolean {
    return this.$store.state.online
  }

  get errors(): ConfigError[] {
    return this.$store.state.errors
  }

  get hasErrors(): boolean {
    return this.errors.length > 0
  }

  get hasConfigVerifyErrors(): boolean {
    return hasConfigVerifyErrors(this.errors)
  }

  get apiBaseUrl(): string {
    return this.$store.getters.apiBaseUrl
  }

  get ConfigErrorId() {
    return ConfigErrorId
  }

  /*
   * Methods
   */

  public ellipsisUnnecessaryParams = ellipsisUnnecessaryParams
  public formatConfigExtract = formatConfigExtract
  public getTileDocUrl = getTileDocUrl
  public guessExpectedValue = guessExpectedValue
  public guessExpectedFieldName = guessExpectedFieldName
  public parsedExtractFieldValue = parsedExtractFieldValue
  public splitList = splitList

  /*
   * Watchers
   */

  @Watch('errors')
  private async scrollToFirstConfigErrorExtractMark() {
    await Vue.nextTick()
    Array.from(document.querySelectorAll('.has-mark')).forEach((errorConfigExtract: Element) => {
      const pre = errorConfigExtract.parentNode?.parentNode as HTMLElement
      const mark = pre.querySelector('mark')

      if (mark === null) {
        return
      }

      pre.scrollTop = mark.offsetTop - pre.offsetTop
    })
  }
}
