























































import Vue from 'vue'
import {Component, Prop} from 'vue-property-decorator'

import TileType from '@/enums/tileType'

@Component
export default class MonitororTileIcon extends Vue {
  /*
   * Props
   */

  @Prop()
  private tileType!: TileType

  /*
   * Computed
   */

  get isGroup(): boolean {
    return this.tileType === TileType.Group
  }

  get isHttp(): boolean {
    return [
      TileType.HttpStatus,
      TileType.HttpRaw,
      TileType.HttpFormatted,
    ].includes(this.tileType)
  }

  get isPing(): boolean {
    return this.tileType === TileType.Ping
  }

  get isPort(): boolean {
    return this.tileType === TileType.Port
  }

  get isPingdom(): boolean {
    return [
      TileType.PingdomCheck,
      TileType.PingdomTransactionCheck,
    ].includes(this.tileType)
  }

  get isGitHub(): boolean {
    return [
      TileType.GitHubChecks,
      TileType.GitHubPullRequest,
      TileType.GitHubCount,
    ].includes(this.tileType)
  }

  get isGitLab(): boolean {
    return [
      TileType.GitLabPipeline,
      TileType.GitLabMergeRequest,
      TileType.GitLabIssues,
    ].includes(this.tileType)
  }

  get isTravis(): boolean {
    return this.tileType === TileType.Travis
  }

  get isJenkins(): boolean {
    return this.tileType === TileType.Jenkins
  }

  get isAzure(): boolean {
    return [
      TileType.AzureDevOpsBuild,
      TileType.AzureDevOpsRelease,
    ].includes(this.tileType)
  }
}
