




































































import {Component} from 'vue-property-decorator'

import DISPLAYABLE_SUBTILE_STATUS from '@/constants/displayableSubtileStatus'
import TileStatus from '@/enums/tileStatus'
import TileType from '@/enums/tileType'
import TileValueUnit from '@/enums/tileValueUnit'
import TileConfig from '@/interfaces/tileConfig'

import AbstractMonitororTile from '@/classes/monitororTile'
import MonitororSubTile from '@/components/SubTile.vue'
import MonitororTileIcon from '@/components/TileIcon.vue'
import TileValue from '@/interfaces/tileValue'

@Component({
  components: {
    MonitororSubTile,
    MonitororTileIcon,
  },
})
export default class MonitororTile extends AbstractMonitororTile {

  /*
   * Computed
   */

  get classes() {
    return {
      ['c-monitoror-tile__theme-' + this.theme]: true,
      'c-monitoror-tile__empty': this.isEmpty,
      'c-monitoror-tile__group': this.isGroup,
      'c-monitoror-tile__status-succeeded': this.isSucceeded,
      'c-monitoror-tile__status-failed': this.isFailed,
      'c-monitoror-tile__status-warning': this.isWarning,
      'c-monitoror-tile__status-running': this.isRunning,
      'c-monitoror-tile__status-queued': this.isQueued,
      'c-monitoror-tile__status-canceled': this.status === TileStatus.Canceled,
      'c-monitoror-tile__status-action-required': this.status === TileStatus.ActionRequired,
    }
  }

  get styles() {
    const styles = {
      'grid-column': `auto / span ${this.columnSpan}`,
      'grid-row': `auto / span ${this.rowSpan}`,
      '--row-span': this.rowSpan,
    }

    return styles
  }

  get isEmpty(): boolean {
    return this.type === TileType.Empty
  }

  get isGroup(): boolean {
    return this.type === TileType.Group
  }

  get columnSpan(): number {
    return this.config.columnSpan || 1
  }

  get rowSpan(): number {
    return this.config.rowSpan || 1
  }

  get displayedSubTiles(): TileConfig[] | undefined {
    if (!this.config.tiles) {
      return
    }

    const displayedSubTiles = this.config.tiles.filter((subTile) => {
      if (!this.$store.state.tilesState.hasOwnProperty(subTile.stateKey)) {
        return false
      }

      return DISPLAYABLE_SUBTILE_STATUS.includes(this.$store.state.tilesState[subTile.stateKey].status)
    })

    return displayedSubTiles
  }

  get value(): TileValue | undefined {
    if (this.state === undefined) {
      return
    }

    return this.state.value
  }

  get message(): string | undefined {
    if (this.state === undefined) {
      return
    }

    return this.state.message
  }

  get buildId(): string | undefined {
    if (this.build === undefined) {
      return
    }

    return this.build.id
  }

  get unit(): TileValueUnit {
    if (this.value === undefined) {
      return TileValueUnit.Raw
    }

    return this.value.unit as TileValueUnit
  }

  get values(): string[] | undefined {
    if (this.value === undefined) {
      return
    }

    return this.value.values
  }

  get displayedValue(): string | undefined {
    if (this.values === undefined) {
      return
    }

    const UNIT_DISPLAY = {
      [TileValueUnit.Millisecond]: 'ms',
      [TileValueUnit.Ratio]: '%',
      [TileValueUnit.Number]: '',
      [TileValueUnit.Raw]: '',
    }

    let value = this.values[this.values.length - 1]
    if (this.unit === TileValueUnit.Millisecond) {
      value = Math.round(parseFloat(value)).toString()
    } else if (this.unit === TileValueUnit.Ratio) {
      value = (parseFloat(value) * 100).toFixed(2).toString()
    }

    return value + UNIT_DISPLAY[this.unit]
  }
}
