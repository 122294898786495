


















































import {Component, Vue} from 'vue-property-decorator'

import MonitororErrors from '@/components/Errors.vue'
import MonitororTile from '@/components/Tile.vue'
import MonitororWelcome from '@/components/Welcome.vue'
import hasConfigVerifyErrors from '@/helpers/hasConfigVerifyErrors'
import ConfigError from '@/interfaces/configError'
import TileConfig from '@/interfaces/tileConfig'

@Component({
  components: {
    MonitororErrors,
    MonitororTile,
    MonitororWelcome,
  },
})
export default class App extends Vue {
  private static readonly SHOW_CURSOR_DELAY: number = 10 // 10 seconds

  /*
   * Data
   */

  private shouldShowCursor: boolean = true
  private shouldDisableTransitions: boolean = false
  private shouldShowCursorTimeout!: number
  private shouldDisableTransitionsTimeout!: number
  private taskRunnerInterval!: number
  private isReady: boolean = false

  /*
   * Computed
   */

  get classes() {
    return {
      'c-app__disable-transitions': this.shouldDisableTransitions,
      ['c-app__theme-' + this.theme]: true,
      'c-app__show-cursor': this.hasConfigVerifyErrors || this.shouldShowCursor,
      'c-app__no-scroll': !this.shouldShowWelcomePage && !this.hasConfigVerifyErrors,
      'c-app__config-verify-errors': !this.shouldShowWelcomePage && this.hasConfigVerifyErrors,
      'c-app__welcome-page': this.shouldShowWelcomePage,
    }
  }

  get cssProperties() {
    const tilesCount = this.tiles.reduce((accumulator, tile) => {
      return accumulator + (tile.rowSpan || 1) * (tile.columnSpan || 1)
    }, 0)

    return {
      '--columns': this.columns,
      '--rows': Math.ceil(tilesCount / this.columns),
      '--zoom': this.zoom,
    }
  }

  get appLoadingClasses() {
    return {
      'c-app--loading__error': !this.shouldShowWelcomePage && this.isOnline && this.hasErrors,
      'c-app--loading__warning': !this.isOnline,
      'c-app--loading__config-verify-errors': !this.shouldShowWelcomePage && this.hasConfigVerifyErrors,
      'c-app--loading__small-logo': this.shouldShowWelcomePage || this.hasConfigVerifyErrors,
    }
  }

  get shouldInit(): boolean {
    return this.$store.getters.shouldInit
  }

  get columns(): number {
    return this.$store.state.columns
  }

  get zoom(): number {
    return this.$store.state.zoom
  }

  get tiles(): TileConfig[] {
    return this.$store.state.tiles
  }

  get errors(): ConfigError[] {
    return this.$store.state.errors
  }

  get hasErrors(): boolean {
    return this.errors.length > 0
  }

  get hasConfigVerifyErrors(): boolean {
    return hasConfigVerifyErrors(this.errors)
  }

  get loadingProgress(): number {
    return this.$store.getters.loadingProgress
  }

  get loadingProgressBarStyle() {
    return {
      transform: `translateX(-${100 - this.loadingProgress * 100}%)`,
    }
  }

  get shouldShowLoading(): boolean {
    return this.loadingProgress < 1 || !this.isOnline || this.hasErrors
  }

  get shouldShowWelcomePage(): boolean {
    return this.isReady && this.$store.getters.shouldShowWelcomePage
  }

  get isOnline(): boolean {
    return this.$store.state.online
  }

  get theme(): string {
    return this.$store.getters.theme.toString().toLowerCase()
  }

  /*
   * Methods
   */

  public resetShowCursorTimeout() {
    clearTimeout(this.shouldShowCursorTimeout)
    this.shouldShowCursor = true
    this.shouldShowCursorTimeout = setTimeout(() => {
      this.shouldShowCursor = false
    }, App.SHOW_CURSOR_DELAY * 1000)
  }

  public dispatchUpdateNetworkState() {
    return this.$store.dispatch('updateNetworkState')
  }

  public onResize() {
    clearTimeout(this.shouldDisableTransitionsTimeout)
    this.shouldDisableTransitions = true
    this.shouldShowCursorTimeout = setTimeout(() => {
      this.shouldDisableTransitions = false
    }, App.SHOW_CURSOR_DELAY * 1000)
  }

  /*
   * Hooks
   */

  private async mounted() {
    await Vue.nextTick()

    window.addEventListener('online', this.dispatchUpdateNetworkState)
    window.addEventListener('offline', this.dispatchUpdateNetworkState)
    window.addEventListener('resize', this.onResize)

    this.taskRunnerInterval = setInterval(() => {
      this.$store.dispatch('runTasks')
    }, 50)

    if (this.shouldInit) {
      await this.$store.dispatch('init')
    }

    setTimeout(() => {
      this.isReady = true
    })
  }

  private beforeDestroy() {
    window.removeEventListener('online', this.dispatchUpdateNetworkState)
    window.removeEventListener('offline', this.dispatchUpdateNetworkState)
    window.removeEventListener('resize', this.onResize)

    clearTimeout(this.shouldShowCursorTimeout)
    clearInterval(this.taskRunnerInterval)

    this.$store.dispatch('killAllTasks', [])
  }
}
