


























































































































import {Component, Vue} from 'vue-property-decorator'

import Route from '@/enums/route'
import ConfigMetadata from '@/interfaces/configMetadata'

@Component({})
export default class MonitororWelcome extends Vue {
  /*
   * Data
   */
  private currentStep: number = 1
  private shouldHaveScroll: boolean = false

  /*
   * Computed
   */

  get classes() {
    return {
      'c-monitoror-welcome__no-scroll': !this.shouldHaveScroll,
    }
  }

  get shouldShowWelcomeNextStep(): boolean {
    const isOnWelcomeNextStepRoute = Route.Welcome === this.$store.getters.currentRoute

    return isOnWelcomeNextStepRoute || this.$store.getters.isNewUser
  }

  get configList(): ConfigMetadata[] {
    return this.$store.state.configList
  }

  get appPort(): string {
    const protocolPort = window.location.protocol === 'https:' ? '443' : '80'

    return window.location.port || protocolPort
  }

  /*
   * Methods
   */

  private mounted() {
    this.$store.dispatch('fetchConfigList')

    setTimeout(() => {
      this.shouldHaveScroll = true
    }, 1650)
  }
}
