





























import {Component} from 'vue-property-decorator'

import TileStatus from '@/enums/tileStatus'

import AbstractMonitororTile from '@/classes/monitororTile'
import MonitororTileIcon from '@/components/TileIcon.vue'

@Component({
  components: {
    MonitororTileIcon,
  },
})
export default class MonitororSubTile extends AbstractMonitororTile {
  /*
   * Computed
   */

  get classes() {
    return {
      ['c-monitoror-sub-tile__theme-' + this.theme]: true,
      'c-monitoror-sub-tile__status-succeeded': this.isSucceeded,
      'c-monitoror-sub-tile__status-failed': this.isFailed,
      'c-monitoror-sub-tile__status-warning': this.isWarning,
      'c-monitoror-sub-tile__status-running': this.isRunning,
      'c-monitoror-sub-tile__status-queued': this.isQueued,
      'c-monitoror-sub-tile__status-canceled': this.status === TileStatus.Canceled,
      'c-monitoror-sub-tile__status-action-required': this.status === TileStatus.ActionRequired,
    }
  }

  get progressTime(): string | undefined {
    if (super.progressTime === undefined) {
      return
    }

    return super.progressTime.replace('Overtime: ', '')
  }
}
